@function map-deep-get($map, $keys...) {
  $scope: $map;
  $i: 1;
  @while (type-of($scope) == map) and ($i <= length($keys)) {
    $scope: map-get($scope, nth($keys, $i));
    $i: $i + 1;
  }
  @return $scope;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin transition($property, $duration, $easing: linear) {
  transition: $property $duration $easing;
  -webkit-transition: $property $duration $easing;
  -moz-transition: $property $duration $easing;
}

@mixin bg-gradient($deg, $firstColor, $secondColor) {
  background: -moz-linear-gradient($deg, $firstColor 0%, $secondColor 100%);
  background: -webkit-linear-gradient($deg, $firstColor 0%, $secondColor 100%);
  background: linear-gradient($deg, $firstColor 0%, $secondColor 100%);
}

// XS
@media only screen and (max-width: 599px) {
}
// SM
@media (min-width: 600px) and (max-width: 959px) {
}
// MD
@media (min-width: 960px) and (max-width: 1279px) {
}
// LG
@media (min-width: 1280px) and (max-width: 1919px) {
}
// XL
@media (min-width: 1920px) {
}
