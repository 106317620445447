@import '../../assets/scss/helper';
@import '../../assets/scss/theme';

html,
body {
  height: 100%;
}
html {
  display: table;
  margin: auto;
}
body {
  display: table-cell;
  vertical-align: middle;
}

html {
  body {
    @include bg-gradient(
      135deg,
      lighten(map-deep-get($theme, 'background', 'dark'), 20%),
      map-deep-get($theme, 'background', 'dark')
    );
    background-attachment: fixed;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../../assets/img/pattern/pattern.png');
      background-repeat: repeat;
      opacity: 0.03;
      z-index: -1;
    }
  }
}

.Ops {
  margin: 60px 0;

  .content {
    .box-text {
      .brand {
        margin-bottom: 30px;
        &-img {
          height: 100px;
          margin-right: 40px;
        }
        &-name {
          height: 120px;
        }
      }

      h1 {
        margin-bottom: 30px;
      }
    }

    .box-img {
      flex: 1;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .social-media {
    margin-top: 50px;
    text-align: center;

    p {
      font-weight: 100;
      letter-spacing: 3px;
      margin-bottom: 15px;
    }

    .icon {
      @extend .gd1;
      @include border-radius(map-get($sys, 'border-radius'));
      color: white;
      padding: 8px;
    }
  }

  .developed {
    position: fixed;
    right: 30px;
    bottom: -35px;
    opacity: 0.3;
    * {
      transition: all ease 0.4s;
    }
    transition: all ease 0.4s;

    &:hover {
      opacity: 1;
      bottom: -15px;
      a p {
        margin-top: 10px;
      }
    }

    a {
      text-decoration: none;
      display: flex;

      p {
        color: $light-color;
        font-size: 13px;
      }

      .dev-profile {
        position: relative;

        img {
          position: absolute;
          height: 65px;
          z-index: 1;
          top: -15px;
          left: 8px;
        }
        .bg-triangle {
          transform: rotate(34deg);
          width: 60px;
          height: 60px;
          @include bg-gradient(
            71deg,
            $dark-color,
            map-deep-get($theme, 'pallet', 'primary', 'main')
          );
        }
      }
    }
  }
}

// XS + SM + MD
@media (max-width: 1279px) {
  .Ops {
    .content {
      flex-direction: column;

      .box-text {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .brand img {
          height: 80px;
        }

        h1 {
          text-align: center;
          font-size: 7vw;
        }
      }
    }

    .social-media {
      margin-top: 0px;
    }
  }
}
