$text-light: #fff;
$text-dark: #000;

$light-color: #f4f2f9;
$dark-color: #1d202b;

$theme: (
  pallet: (
    primary: (
      main: #631bec,
      dark: #440eaa,
      contrastText: $text-light,
    ),
    secondary: (
      main: #0ed8cf,
      dark: #0cb3ac,
      contrastText: $text-dark,
    ),
    tertiary: (
      main: #ff8534,
      dark: #bd4c00,
      contrastText: $text-light,
    ),
    quaternary: (
      main: #fd0abb,
      dark: #b70186,
      contrastText: $text-light,
    ),
    light: (
      main: $light-color,
      dark: #e1dae6,
      contrastText: $text-dark,
    ),
    dark: (
      main: $dark-color,
      contrastText: $text-light,
    ),
  ),
  text: (
    default: $text-dark,
    primary: $text-light,
    secondary: #9797a3,
  ),
  background: (
    light: $light-color,
    dark: $dark-color,
  ),
);
$sys: (
  border-radius: 16px,
);

button {
  transition: all 0.3s ease 0s;
  @include border-radius(50px !important);

  &.Mui-disabled {
    opacity: 0.2;
  }

  &.MuiButton-contained {
    box-shadow: 0px 6px 5px rgba($dark-color, 0.1);
  }
}

@each $key, $value in map-deep-get($theme, 'pallet') {
  // default
  .btn-#{$key} {
    color: map-deep-get($value, 'main') !important;

    &:hover {
      color: map-deep-get($value, 'main') !important;
      background-color: rgba(map-deep-get($value, 'main'), 0.1) !important;
      box-shadow: 0px 6px 19px -6px rgba(map-deep-get($value, 'main'), 0.2);
    }
    &.Mui-disabled.btn-#{$key} {
      opacity: 0.5;
    }
  }

  //.MuiButton-outlined
  .MuiButton-outlined {
    &.btn-#{$key} {
      background-color: transparent !important;
      border-color: rgba(map-deep-get($value, 'main'), 0.5) !important;

      &:hover {
        border-color: rgba(map-deep-get($value, 'main'), 1) !important;
        background-color: rgba(map-deep-get($value, 'main'), 0.1) !important;
      }
    }
  }

  // contained
  .MuiButton-contained {
    &.btn-#{$key} {
      color: map-deep-get($value, 'contrastText') !important;
      background-color: map-deep-get($value, 'main') !important;

      &:hover {
        @if map-deep-get($value, 'dark') {
          background: map-deep-get($value, 'dark') !important;
        } @else {
          background: darken(map-deep-get($value, 'main'), 10%) !important;
        }

        box-shadow: 0px 6px 19px -6px rgba(map-deep-get($value, 'main'), 0.8);
      }
    }
  }

  .text-#{$key} {
    color: map-deep-get($value, 'main');
  }

  .bg-#{$key} {
    background: map-deep-get($value, 'main');
  }
}

// Gradient Background
.gd1 {
  @include bg-gradient(
    90deg,
    map-deep-get($theme, 'pallet', 'primary', 'main'),
    map-deep-get($theme, 'pallet', 'secondary', 'main')
  );
}
.gd2 {
  @include bg-gradient(
    90deg,
    map-deep-get($theme, 'pallet', 'quaternary', 'main'),
    map-deep-get($theme, 'pallet', 'tertiary', 'main')
  );
}

.btn-gd1,
.btn-gd2 {
  color: $text-light !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;

  &.MuiButton-outlined {
  }

  &:hover {
    -webkit-background-clip: border-box !important;
    -webkit-text-fill-color: inherit !important;
  }
}

.btn-gd1 {
  @extend .gd1;

  &:hover {
    color: map-deep-get($theme, 'pallet', 'primary', 'main') !important;
    @include bg-gradient(
      90deg,
      rgba(map-deep-get($theme, 'pallet', 'primary', 'main'), 0.2),
      rgba(map-deep-get($theme, 'pallet', 'secondary', 'main'), 0.2)
    );
    box-shadow: 0px 6px 19px -6px rgba(map-deep-get($theme, 'pallet', 'primary', 'main'), 0.2),
      0px 6px 19px -6px rgba(map-deep-get($theme, 'pallet', 'secondary', 'main'), 0.4);
  }

  &.MuiButton-contained {
    @include bg-gradient(
      90deg,
      map-deep-get($theme, 'pallet', 'primary', 'main'),
      map-deep-get($theme, 'pallet', 'secondary', 'main')
    );
    color: $text-light !important;
    -webkit-background-clip: border-box !important;
    -webkit-text-fill-color: inherit !important;
    &:hover {
      box-shadow: 0px 6px 19px -6px rgba(map-deep-get($theme, 'pallet', 'primary', 'main'), 0.8),
        0px 6px 19px -6px rgba(map-deep-get($theme, 'pallet', 'secondary', 'main'), 1);
    }
  }

  &.MuiButton-outlined {
    border-color: rgba(map-deep-get($theme, 'pallet', 'primary', 'main'), 0.5);
    &:hover {
      border-color: map-deep-get($theme, 'pallet', 'primary', 'main');
    }
  }
}

.btn-gd2 {
  @extend .gd2;

  &:hover {
    color: map-deep-get($theme, 'pallet', 'quaternary', 'main') !important;
    @include bg-gradient(
      90deg,
      rgba(map-deep-get($theme, 'pallet', 'quaternary', 'main'), 0.2),
      rgba(map-deep-get($theme, 'pallet', 'tertiary', 'main'), 0.2)
    );
    box-shadow: 0px 6px 19px -6px rgba(map-deep-get($theme, 'pallet', 'quaternary', 'main'), 0.2),
      0px 6px 19px -6px rgba(map-deep-get($theme, 'pallet', 'tertiary', 'main'), 0.4);
  }

  &.MuiButton-contained {
    @include bg-gradient(
      90deg,
      map-deep-get($theme, 'pallet', 'quaternary', 'main'),
      map-deep-get($theme, 'pallet', 'tertiary', 'main')
    );
    color: $text-light !important;
    -webkit-background-clip: border-box !important;
    -webkit-text-fill-color: inherit !important;
    &:hover {
      box-shadow: 0px 6px 19px -6px rgba(map-deep-get($theme, 'pallet', 'quaternary', 'main'), 0.8),
        0px 6px 19px -6px rgba(map-deep-get($theme, 'pallet', 'tertiary', 'main'), 1);
    }
  }

  &.MuiButton-outlined {
    border-color: rgba(
      map-deep-get($theme, 'pallet', 'quaternary', 'main'),
      0.5
    );
    &:hover {
      border-color: map-deep-get($theme, 'pallet', 'quaternary', 'main');
    }
  }
}
