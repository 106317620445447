@import './assets/scss/helper';
@import './assets/scss/theme';
@import './assets/scss/typography';

body {
  font-size: 17px;
  line-height: 1.47059;
  font-weight: 400;
}

ul,
ol,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
p,
blockquote,
figure,
form,
fieldset,
input,
legend,
pre,
abbr,
button {
  margin: 0;
  padding: 0;
}

.menu {
  &,
  .MuiDrawer-paper,
  .MuiBackdrop-root {
    top: 62px;
  }

  .links {
    color: $text-dark;
    text-decoration: none;

    li {
      padding: 25px;
      &:hover {
        color: $text-light;
        @extend .gd1;
      }
    }
  }
}

// XS
@media only screen and (max-width: 599px) {
}
// SM
@media (min-width: 600px) and (max-width: 959px) {
}
// MD
@media (min-width: 960px) and (max-width: 1279px) {
}
// LG
@media (min-width: 1280px) and (max-width: 1919px) {
}
// XL
@media (min-width: 1920px) {
}
